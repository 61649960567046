import React, { FC, useContext, useEffect, useState } from 'react'
import RequestConfirmation from '@components/ui/requestConfirmation'
import ProgressBar from '@components/ui/progressBar'
import SectionTitle from '@components/ui/sectionTitle'
import Text from '@components/ui/text'
import SelectionPDF from '@components/requestForms/selection.pdf.form'
import RequestCore, { RequestFormType } from '@templates/requests/create.core'
import { PageTitle } from '@components/configs/PageTitle'
import { joinClasses } from '@utils/styles'
import { useAppDispatch } from '@services/store'
import {
	populateRequestModelState,
	populateRequestConfigsState,
	populateRequestLocationState
} from '@services/store/request'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import {
	IRequestConfigsState,
	IRequestLocationState
} from '@services/store/request/type'
import * as pageUtils from '@templates/requests/__create.utils'
import {
	convertStringToNumber,
	formatHtmlForStrapiText,
	formatStrapiText,
	saveFileToStore,
	saveSeparetedFileToStore
} from '@utils/methods'
import config from '@utils/config'
import { navigate } from 'gatsby'
import { chevronLightBlueIcon, externalLink } from '@images/icons'
import { Icon } from '@components/ui/Icon'
import {
	fileInputList,
	LanguageEnum,
	REQUEST_CONFIGS_KEYS,
	REQUEST_TN_DETAILS_STEPS_NAME,
	requestCategories
} from '@services/constants'
import { Collapse } from 'react-collapse'
import {
	AddressInputName,
	RequestInputFiles,
	RequestInputs,
	PlantedTree,
	TNRequestNames,
	PesticideCertificates,
	VehicleInformation,
	TankTruck,
	AnimalInformation,
	AnimalInformationFile,
	AnimalRequiredFileAttributes,
	ParkingPermitInformation,
	AdministrativeRiviewInfo
} from '@utils/request'
import { IWaterCounterAddressDto, RequestMetadata } from '@services/types'
import { REQUEST_CONFIG_KEY_RELATION } from '@services/store/request/constant'
import { Scrapper } from '@services/models/scrapper.model'
import { URL_PARAMS } from '@services/constants'
import Loader from '@components/ui/loader'
import RequestFormPDFSummary from '@components/ui/RequestFormPDFSummary/requestPDFSummary'
import MapSelection from '@components/requestForms/mapSelection.pdf.form'
import Modal from '@components/ui/modal'
import { resScrapperData } from '@services/types/scrapper'
import { useAppSelector } from '@services/store'
import RequiredIcon from '@components/ui/requiredIcon'
import EligibilityRequirement, {
	ACCORDION_DESCRIPTION
} from '@components/ui/eligibilityRequirement'
import PublicAuctionsForm from '@components/requestForms/publicAuctions.form'
import Notification from '@components/ui/notification'
import { pageUrlDic } from '@templates/requests/__create.utils'
import RequestFailedModal from '@components/ui/requestFailedModal'
import AddressValidationModal from '@components/ui/addressValidationModal'
import {
	getAddressByCoordinates,
	getDefaultCadastralNumber
} from '@components/ui/addressForm/__index.utils'
import {
	AddressCoordinates,
	Contact,
	RedesignWebPortal,
	User
} from '@services/models'
import { useAccount, useMsal } from '@azure/msal-react'
import { Coordinate } from '@components/ui/map/mapForm.interfaces'
import CompactFooter from '@components/ui/footer/compactFooter'
import { Request } from 'typings/shared'
import Invoice from '@components/ui/invoice'
import DisplayInforModal from '@components/ui/modals/displayInfoModal'
import {
	annualPermitAndTagForAnimalsFileNames,
	parkingPermitFileNames
} from '@components/requestForms/__forms.utils'
import moment from 'moment'
import { TAB } from '@pages/auth/profile/__index.utils'
import ProfilePageRedirectModal from '@components/ui/modals/profilePageRedirectModal'
import axios, { AxiosError } from 'axios'

export type thirdStepSubSteps = {
	map: boolean
	details_1: boolean
	details_2?: boolean
	details_3?: boolean
	details_4?: boolean
	requiredDocument: boolean
	steps: string[]
	position: REQUEST_TN_DETAILS_STEPS_NAME
}

export type filledInformation = {
	inputs: RequestInputs[]
	inputFiles: RequestInputFiles[]
	extraFiles: Set<File>
}

const CreateRequestPdf: FC<pageUtils.Props> = ({ pageContext: { id } }) => {
	const {
		title,
		pageAssets,
		request,
		steps,
		listSteps,
		currentStep,
		loading,
		requestCreated,
		browser,
		inputs,
		closeLink,
		isAuthUserInLaval,
		address,
		cadastralAddress,
		apartment,
		errors,
		coordinates,
		filesInputs,
		city,
		postalCode,
		useDefaultUserLocation,
		requestHasError,
		createdRequestWithInvoice,
		setErrors,
		onAddErrorFromAddress,
		setApartment,
		setAddress,
		setCurrentStep,
		onSubmit,
		setInputs,
		setDoSentPost,
		setCoordinates,
		setFilesInputs,
		setCity,
		setPostalCode,
		setUseDefaultUserLocation,
		setCadastralAddress,
		setRequestHasError,
		onResetRequestFailed,
		isDeclarationRequest,
		isRequestHasPayementOnDepot
	}: RequestFormType = RequestCore(id)
	const { setAuthUser, authUser, language } =
		useContext<AppContextProps>(AppStateContext)
	const customLocationState = useAppSelector((state) => state.request.location)
	const answers = useAppSelector((state) => state.request.configs)

	const dispatch = useAppDispatch()

	const [isAddressRequired, setIsAddressRequired] = useState<boolean>(false)
	const [extraFiles, setExtraFiles] = useState<Set<File>>(new Set<File>())
	const [hasFileError, setHasFileError] = useState<boolean>(false)
	const [hasFileRequiredError, setHasFileRequiredError] =
		useState<boolean>(false)
	const [subSteps, setSubSteps] = useState<thirdStepSubSteps>()

	type RequestInputFilesArray = Array<RequestInputFiles<any>>
	const [inputFiles, setInputFiles] = useState<RequestInputFilesArray>([])
	const [pageId, setPageId] = useState<string>('')
	const [eligibilityRequirement, setEligibilityRequirement] = useState<
		string | resScrapperData['json']
	>()
	const [conditionsAccepted, setConditionsAccepted] = useState<boolean>(false)
	const [customError, setCustomError] = useState<boolean>(false)
	const [mapLabel, setMapLabel] = useState<string>(
		pageAssets?.request_form_step_map
	)
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
	const [isEligibilityAvailable, setIsEligibilityAvailable] =
		useState<boolean>(true)
	const [disableNextButton, setDisableNextButton] = useState<boolean>(true)
	const [isValidating, setIsValidating] = useState<boolean>(false)
	const [openValidationModal, setOpenValidationModal] = useState<boolean>(false)
	const [shouldValidateAddress, setShouldValidateAddress] =
		useState<boolean>(false)
	const [shouldMapDisplay, setShouldMapDisplay] = useState<boolean>(false)
	const [shouldCheckAddress, setShouldCheckAddress] = useState<boolean>(false)
	const [isUpdatingAddress, setIsUpdatingAddress] = useState<boolean>(false)
	const [mapCoordinates, setMapCoordinates] = useState<Coordinate>()
	const [mapAddress, setMapAddress] = useState<string>('')
	const [mapPostalCode, setMapPostalCode] = useState<string>()
	const [mapCity, setMapCity] = useState<string>('')
	const [proposedAddress, setProposedAddress] = useState<any>()
	const { accounts } = useMsal() ?? []
	const account = useAccount(accounts[0] || {})
	const contact = authUser?.profile || new Contact()
	const [stepDescription, setStepDescription] = useState(listSteps)
	const [requiredFieldAttributes, setRequiredFieldAttributes] = useState<
		string[][]
	>([])
	const [requiredFileAttributes, setRequiredFileAttributes] = useState<
		string[]
	>([])
	const [isRequestAddressOutsideLaval, setIsRequestAddressOutsideLaval] =
		useState<boolean>(false)
	const [
		isAddressOutsideLavalMessageShow,
		setIsAddressOutsideLavalMessageShow
	] = useState<boolean>(false)

	const scrappy = async () => {
		const pageId = location.pathname.split('/').pop()!

		if (pageUtils.pageUrlDic[pageId].pageId) {
			let success = await vortexFetchRequest(
				pageUtils.pageUrlDic[pageId].pageId
			)

			if (success) {
				return
			}
		}

		if (pageId == pageUtils.FORMS_ID.ANNUAL_PERMIT_AND_TAG_FOR_ANIMALS) {
			const accRequirement = getAdmissibleConditionFromStrapiData(
				pageAssets?.form_annualPermitAndTagForAnimals_admissible_condition
			)
			setEligibilityRequirement(accRequirement)
			return
		}

		if (pageId == pageUtils.FORMS_ID.MUNICIPAL_EVALUATION_FORM) {
			const accRequirement = getAdmissibleConditionFromStrapiData(
				pageAssets?.form_municipalEvaluation_admissible_condition
			)
			setEligibilityRequirement(accRequirement)
			return
		}

		const scrap = new Scrapper({
			url: pageUtils.pageUrlDic[pageId]?.url,
			selector: '.mondossier-show'
		})
		await scrap.launchScrapping().then((data) => {
			if (!!data.length) {
				const isForPermitPesticide =
					pageId ==
					pageUtils.FORMS_ID
						.TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM

				switch (pageId) {
					case pageUtils.FORMS_ID.HERITAGE_BUILDINGS_REVITALIZATION_PROGRAM:
						const requirements = formatHeritageBuildingEligibility(data)
						setEligibilityRequirement(requirements)
						break
					case pageUtils.FORMS_ID.PERMIT_OCCUPATION_OF_PUBLIC_HIGHWAY:
						const permitRequirements = formatOccupationEligibility(data)
						setEligibilityRequirement(permitRequirements)
						break
					case pageUtils.FORMS_ID
						.TEMPORARY_PESTICIDE_APPLICATION_PERMIT_FOR_INDIVIDUALS_FORM:
					case pageUtils.FORMS_ID
						.ANNUAL_REGISTRATION_CERTIFICATE_FOR_CONTRACTORS:
						const requirement = formatPesticideEligibility(
							data,
							isForPermitPesticide
						)
						setEligibilityRequirement(requirement)
						break
					default:
						const position = pageUtils.pageUrlDic[pageId].position
						if (!Array.isArray(position)) {
							let htmlText: string | resScrapperData['json'] =
								data[position].json ||
								formartEligibilityRequirementBody(data[position].html, pageId)
							setEligibilityRequirement(
								htmlText || pageAssets?.scrapper_error_occurred
							)
						} else {
							const accordionData: resScrapperData['json'] = []
							const positions = position as number[]
							positions
								.map((position) => data[position])
								.forEach((result) => {
									if (!result.json) {
										const htmlString = result.html
										const { title, summary } = extractDataFromText(htmlString)
										accordionData.push({
											accordion: {
												Title: title,
												Summary: summary
											}
										})
									} else {
										accordionData.push(...result.json)
									}
								})

							const formatedData = accordionData.map((acc, index) => {
								return {
									accordion: {
										Title: acc.accordion.Title,
										Summary: formartEligibilityRequirementBody(
											acc.accordion.Summary,
											pageId,
											index
										)
									}
								}
							})

							setEligibilityRequirement(
								formatedData || pageAssets?.scrapper_error_occurred
							)
						}
						break
				}
			} else {
				setEligibilityRequirement(pageAssets?.scrapper_error_occurred)
			}
		})
	}

	const vortexFetchRequest = async (pageId: string) => {
		const instance = new RedesignWebPortal()
		const response = await instance.getRedesignWebPortalHtml(pageId)
		let htmlString = response.toString()
		let sliceIndex = 1

		// CONVERT TEXT TO ACCORDION

		const accordionData: resScrapperData['json'] = []

		/*if (
			pageId ===
			pageUtils.pageUrlDic[pageUtils.FORMS_ID.FIRE_HYDRANTS_TANK_FILLING].pageId
		) {
			let formId
			let responseBlocks = response
				.toString()
				.split(
					/(?=<div.*? class="wp-block-vortex-blocks-discoverable-content">)/
				)
				.slice(1)

			switch (location.pathname.split('/').pop()!) {
				case pageUtils.FORMS_ID.FIRE_HYDRANTS_TANK_FILLING:
					formId = `b6bc1424-4df1-4e1d-9c56-6121a9f9d725`
					break
				case pageUtils.FORMS_ID.HYDRAULIC_AND_PRESSURE_TESTS_FORM:
					formId = `ddc86329-85a0-476b-a954-b453feaa1779`
					break
				case pageUtils.FORMS_ID.FIRE_HYDRANTS_TEMP_AQUEDUCT_NETWORK:
					formId = `76da273f-e568-425e-91e3-261463a79cb8`
					break
			}

			//console.log(formId)
			//console.log(responseBlocks)

			htmlString = responseBlocks.find((element) => element.includes(formId))!

			htmlString = htmlString.replace(
				/<p class="accordion__header__title">/g,
				'<h2>'
			)

			htmlString = htmlString.replace(/<\/p>/g, '</h2>')

			htmlString += responseBlocks[responseBlocks.length - 1]

			sliceIndex++
		}*/

		const accordionElements = htmlString?.split(/(?=<h2)/).slice(sliceIndex)

		accordionElements?.map((element) => {
			let currentElement = element

			// ONLY FOR FIRE HYDRANT
			if (
				pageId ===
				pageUtils.pageUrlDic[pageUtils.FORMS_ID.FIRE_HYDRANTS_TANK_FILLING]
					.pageId
			) {
				let splitElement = element.split(
					/(?=<section class="wp-block-vortex-blocks-accordion-item accordion ac" id=)/
				)

				currentElement = splitElement[0]
			}

			const { summary, title } = extractDataFromText(
				formatHtmlForStrapiText(currentElement)
			)
			accordionData.push({
				accordion: {
					Title: title,
					Summary: formatHtmlForStrapiText(summary)
				}
			})
		})

		const formatedData = accordionData.map((acc, index) => {
			return {
				accordion: {
					Title: acc.accordion.Title,
					Summary: formartEligibilityRequirementBody(
						acc.accordion.Summary,
						pageId,
						index
					)
				}
			}
		})

		//SET DATA

		setEligibilityRequirement(
			formatedData || pageAssets?.scrapper_error_occurred
		)

		return true
	}

	const formatHeritageBuildingEligibility = (data: resScrapperData[]) => {
		let result: resScrapperData['json'] = []
		;('')
		let i = 0
		let h = 0
		while (i < data.length) {
			if (data[i].element === 'h2') {
				result.push({
					accordion: {
						Title: data[i].html,
						Summary: ''
					}
				})
				h++
			} else {
				// Special case for heritage building
				const pattern = /<h2.*?<\/h2>/

				let html = data[i].html
				if (pattern.test(html) && data[i].element !== 'h2') {
					const parser = new DOMParser()
					const htmlDoc = parser.parseFromString(html, 'text/html')
					const h2Element = htmlDoc.querySelector('h2')

					result.push({
						accordion: {
							Title: h2Element !== null ? h2Element.outerHTML : '',
							Summary: html.replace(/<h2.*?<\/h2>/, '')
						}
					})
				} else {
					result[h].accordion.Summary += html
				}
			}
			i++
		}
		return result
	}

	const formatOccupationEligibility = (data: resScrapperData[]) => {
		let result: resScrapperData['json'] = []
		const requirementOrder = [
			{
				title:
					pageAssets?.request_occupationHighway_admissible_condition_title1,
				groupOrder: [1, 0]
			},
			{
				title:
					pageAssets?.request_occupationHighway_admissible_condition_title2,
				groupOrder: [4]
			},
			{
				title:
					pageAssets?.request_occupationHighway_admissible_condition_title3,
				groupOrder: [3]
			},
			{
				title:
					pageAssets?.request_occupationHighway_admissible_condition_title4,
				groupOrder: [2]
			}
		]

		requirementOrder.map((element) => {
			const title = element.title
			let summary = ''
			element.groupOrder.map((item) => {
				const dataItem = data[item]
				if (!dataItem?.json) {
					summary += dataItem?.html + '<br />'
				} else {
					dataItem?.json?.map((acc) => {
						summary +=
							`<h4 
						 style="color:#0054a6;"
						>${acc.accordion.Title}</h4>` + acc.accordion.Summary
					})
				}
			})

			result.push({
				accordion: {
					Title: title,
					Summary: summary
				}
			})
		})

		return result
	}

	const formartEligibilityRequirementBody = (
		data: string,
		pageId: string,
		index?: number
	) => {
		switch (pageId) {
			case pageUtils.FORMS_ID.TREE_PLANTING_FORM:
				return data.replace(/<h2.*?<\/h2>/g, '')
			default:
				return data
		}
	}

	const formatPesticideEligibility = (
		data: resScrapperData[],
		isForPermitPesticide: boolean
	) => {
		const scrapHTMLFor: number[] = [0, 2]
		const accordionData: resScrapperData['json'] = []
		data.forEach((result, index) => {
			if (scrapHTMLFor.includes(index)) {
				const { title, doc } = extractDataFromText(result.html)
				const summary =
					index == 2
						? doc?.querySelector('div')?.querySelector('div')?.innerHTML
						: doc?.querySelector('p')?.textContent
				if (title != null && summary != null) {
					accordionData.push({
						accordion: {
							Title: `${title}`,
							Summary: `${summary}`
						}
					})
				}
			}

			if (result.json && index == 1) {
				if (isForPermitPesticide) {
					const { doc } = extractDataFromText(data[0].html)
					const scriptCode = doc?.querySelectorAll('script')[1]?.textContent
					const jsonData = scriptCode?.split('[')[1].split(']')[0]
					const json = JSON.parse(`[${jsonData}]` ?? '')[1]
					accordionData.push({
						accordion: {
							Title: json.accordion.Title,
							Summary: json.accordion.Summary
						}
					})
				} else {
					accordionData.push(result.json[0])
				}
			} else if (result.json && index == 0 && isForPermitPesticide) {
				accordionData.push(...result.json)
			} else if (result.json && index != 2 && !isForPermitPesticide) {
				accordionData.push(...result.json)
			}
		})
		return accordionData
	}

	const extractDataFromText = (text: string): pageUtils.EligibilityContent => {
		const parser = new DOMParser()
		const doc = parser.parseFromString(text, 'text/html')
		const h2Title = doc.querySelector('h2')?.textContent
		const h3Title = doc.querySelector('h3')?.textContent

		const title =
			h2Title ||
			h3Title ||
			pageAssets?.page_requestInfo_eligibility_requirements

		const summary = h2Title
			? text?.replace(/<h2.*?<\/h2>/, '')
			: h3Title
			? text?.replace(/<h3.*?<\/h3>/, '')
			: text
		return {
			title,
			summary,
			doc
		}
	}

	const getAdmissibleConditionFromStrapiData = (
		strapiData: string
	): resScrapperData['json'] => {
		const accordionData: resScrapperData['json'] = []
		const accordionElements = strapiData?.split('<br>')
		accordionElements?.map((element) => {
			const { summary, title } = extractDataFromText(
				formatHtmlForStrapiText(element)
			)
			accordionData.push({
				accordion: {
					Title: title,
					Summary: formatHtmlForStrapiText(summary)
				}
			})
		})
		return accordionData
	}

	useEffect(() => {
		scrappy()
		setPageId(location.pathname.split('/').pop()!)
	}, [])

	const cleanStorage = () => {
		for (let name of fileInputList) {
			localStorage.removeItem(name)
		}
	}

	useEffect(() => {
		cleanStorage()

		return () => {
			cleanStorage()
		}
	}, [])

	useEffect(() => {
		window?.scrollTo({ top: 0, behavior: 'smooth' })
	}, [subSteps, steps])

	useEffect(() => {
		if (coordinates && errors.length > 0) {
			setErrors(errors.filter((error: string) => error !== AddressInputName))
		}
	}, [coordinates])

	const processingData = () => {
		let config: IRequestConfigsState = {} as IRequestConfigsState

		inputs.forEach((input) => {
			if (input.name == 'plantedTreeList') {
				let plantedTreeList = JSON.parse(input.value)

				plantedTreeList.forEach((plantedTree) => {
					plantedTree.cost = Number(plantedTree.cost)
					plantedTree.location = Number(plantedTree.location)
					plantedTree.diameter &&
						(plantedTree.diameter = Number(plantedTree.diameter))
					plantedTree.height &&
						(plantedTree.height = Number(plantedTree.height))
				})

				config[input.name] = plantedTreeList
			} else if (input.name == 'vehicleInformationList') {
				let vehicleInformationList = JSON.parse(input.value)
				config[input.name] = vehicleInformationList
			} else if (input.name == 'concernedAdresse') {
				config['demandAddress'] = input.value
				config['declareAddress'] = input.value

				const addressInput = inputs.find(
					(input) => input.name == 'waterCounterAddress'
				)
				const address: IWaterCounterAddressDto = JSON.parse(
					addressInput?.value || '{}'
				)
				if (address?.adresse_idf) {
					config['declareAddress'] = address.adresse_idf
				}

				config[input.name] = input.value
			} else if (input.name == 'concernedAppartement') {
				config['demandAppartment'] = input.value
				config['declareAppartment'] = input.value
				config[input.name] = input.value
			}else if (input.name == 'counterReadingValue') {
				const normalizedInput: string = input.value?.replace(',', '.')

				config[input.name] = normalizedInput
			} else if (input.name == 'pesticideCertificatesList') {
				let pesticideCertificatesList = JSON.parse(input.value)
				config[input.name] = pesticideCertificatesList
			} else if (input.name == 'tankTruckList') {
				config[input.name] = JSON.parse(input.value)
			} else if (input.name == 'lotNumbersList' && input.value !== '') {
				let lotNumbersList: string[] = JSON.parse(input.value)
				config[input.name] = lotNumbersList
			} else if (input.name == 'parkingPermitList' && input.value !== '') {
				let parkingPermitList: ParkingPermitInformation[] = JSON.parse(
					input.value
				)
				const newList: any = parkingPermitList.map((permit) => {
					return {
						...permit,
						vehicle_owner: permit.vehicle_owner == 'true'
					}
				})
				config[input.name] = newList
			} else if (input.name == 'animalInformationList' && input.value !== '') {
				let animalInformationList = JSON.parse(input.value)

				animalInformationList.forEach((animal) => {
					animal.type = Number(animal.type)
					animal.gender = animal.gender ? Number(animal.gender) : null
					animal.weight = Number(animal.weight)
					animal.weight_unit = Number(animal.weight_unit)
					animal.is_micro_chipped = animal.is_micro_chipped == 'true'
					animal.is_sterilized = animal.is_sterilized == 'true'
					animal.is_rabies_vaccinated = animal.is_rabies_vaccinated == 'true'
					animal.service_animal = animal.service_animal == 'true'
					animal.permit_term = Number(animal.permit_term)
					animal.tag_number_unknown =
						animal.tag_number_unknown?.toString() == 'true'
					animal.is_renewing = animal.is_renewing == 'true'
					animal.is_replacement = Number(animal.is_replacement)
					animal.non_renewal_reason =
						animal.non_renewal_reason !== ''
							? Number(animal.non_renewal_reason)
							: null
				})

				config[input.name] = animalInformationList
			} else if (input.name == 'vehicleInformation' && input.value !== '') {
				let vehicleInformation = JSON.parse(input.value)
				config[input.name] = vehicleInformation
			} else if (input.name == 'dateTimeList') {
				const dateTimeList: AdministrativeRiviewInfo[] = JSON.parse(input.value)

				const calendaDate = JSON.parse(config['calendarDate'] || '[]')
				const AllDates = dateTimeList?.find(
					(dateTime) =>
						dateTime.occpation_value ==
						REQUEST_CONFIGS_KEYS.selectedDays.all_dates
				)
				const WeekDays = dateTimeList?.find(
					(dateTime) =>
						dateTime.occpation_value ==
						REQUEST_CONFIGS_KEYS.selectedDays.week_days
				)
				const WeekEnds = dateTimeList?.find(
					(dateTime) =>
						dateTime.occpation_value ==
						REQUEST_CONFIGS_KEYS.selectedDays.weekend
				)

				const newDataList: AdministrativeRiviewInfo[] = calendaDate?.map(
					(date) => {
						const momentDate = moment(date)
						const isweekend =
							momentDate.weekday() === 0 || momentDate.weekday() === 6
						let occpation_value = ''
						let startTime = ''
						let endTime = ''
						let is_24_7 = false

						if (AllDates) {
							occpation_value = AllDates?.occpation_value
							startTime = `${!AllDates?.is_24_7 ? AllDates?.start_time : ''}`
							endTime = `${!AllDates?.is_24_7 ? AllDates?.end_time : ''}`
							is_24_7 = AllDates?.is_24_7
						} else {
							if (!isweekend && WeekDays) {
								occpation_value =
									WeekDays?.occpation_value ?? WeekEnds?.occpation_value
								startTime = `${
									!WeekDays?.is_24_7
										? WeekDays?.start_time ?? WeekEnds?.start_time
										: ''
								}`
								endTime = `${
									!WeekDays?.is_24_7
										? WeekDays?.end_time ?? WeekEnds?.end_time
										: ''
								}`
								is_24_7 = WeekDays?.is_24_7 ?? is_24_7
							} else {
								occpation_value =
									WeekEnds?.occpation_value || WeekDays?.occpation_value || ''
								startTime = `${
									!WeekEnds?.is_24_7
										? WeekEnds?.start_time ?? WeekDays?.start_time
										: ''
								}`
								endTime = `${
									!WeekEnds?.is_24_7
										? WeekEnds?.end_time ?? WeekDays?.end_time
										: ''
								}`
								is_24_7 = WeekEnds?.is_24_7 ?? is_24_7
							}
						}

						const newData: AdministrativeRiviewInfo = {
							date: moment(date).format('YYYY-MM-DD'),
							start_time: startTime,
							end_time: endTime,
							is_24_7,
							is_weekend: isweekend,
							is_from_config: true,
							occpation_value
						}

						return newData
					}
				)

				config[input.name] = newDataList
			} else if (input.value !== '') {
				let configsInputValue: number | string | boolean = input.value
				switch (REQUEST_CONFIG_KEY_RELATION[input.name]) {
					case 'number':
						configsInputValue = Number(configsInputValue)
						break
					case 'boolean':
						configsInputValue =
							String(configsInputValue)?.toLowerCase() === 'true'
						break
					default:
						break
				}
				config[input.name] = configsInputValue
			} else if (input.name) {
				config['companyInChargeInfo'] = {
					organization_name: config['companyName'],
					address: config['companyAddress'],
					first_name: config['personInchargeFirstName'],
					last_name: config['personInchargeLastName'],
					telephone: config['personInchargePhone'],
					email: config['personInchargeEmail']
				}
				config['supervisorInfo'] = {
					first_name: config['supervisorFirstName'],
					last_name: config['supervisorLastName'],
					telephone: config['supervisorPhone'],
					email: config['supervisorEmail']
				}
				config['signalingResponsibleCompany'] = {
					organization_name: config['organisationName'],
					first_name: config['nameOfPersonResponsible'],
					telephone: config['personResponsiblePhone'],
					email: config['personResponsibleEmail']
				}
				config['buildingOwnerInfo'] = {
					first_name: config['ownerFirstName'],
					last_name: config['ownerLastName'],
					telephone: config['ownerPhoneNumber'],
					email: config['ownerEmail']
				}
				config['poolSellerInfo'] = {
					organization_name: config['companyPoolName'],
					address: config['companyAdresse'],
					city: config['companyCity'],
					postal_code: config['companyPostalCode'],
					telephone: config['companyPhoneNumber'],
					email: config['companyEmail']
				}
			}
		})

		dispatch(populateRequestConfigsState(config))

		if (request?.name == TNRequestNames.rainwaterBarrel) {
			dispatch(populateRequestLocationState(customLocationState))
		} else {
			dispatch(
				populateRequestLocationState({
					city: city || authUser?.profile?.address.city,
					address: address || authUser?.profile?.address.address,
					apartment: apartment || authUser?.profile?.address.apartment,
					postalCode: postalCode || authUser?.profile?.address.postalCode,
					cadastralAddress,
					coordinates: {
						longitude:
							coordinates?.longitude ||
							authUser?.profile?.address.coordinates?.longitude,
						latitude:
							coordinates?.latitude ||
							authUser?.profile?.address.coordinates?.latitude
					}
				})
			)
		}

		dispatch(
			populateRequestModelState({
				files: [...extraFiles]
			})
		)

		setDoSentPost(!hasFileError && !hasFileRequiredError)
	}

	const processingDataAndValidation = (): boolean => {
		let details: string = ''
		let configs: IRequestConfigsState = {} as IRequestConfigsState
		const listErrors: string[] = []
		const metadata: RequestMetadata[] = []
		let isFirstErrorAlreadySet: boolean = false

		if (subSteps && subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.MAP) {
			if (!coordinates && !useDefaultUserLocation) {
				listErrors.push(AddressInputName)
				isFirstErrorAlreadySet = true
			}

			inputs.forEach((input: RequestInputs) => {
				if (
					input.required &&
					[
						'buildingCadastralNumber',
						'isOverYearOwner',
						'notarialRegistrationDate',
						'notarialRegistrationNumber',
						'buildingPostalCode',
						'buildingCity',
						'isLandOwner',
						'propertyIsOwner',
						'declarationExistsOnAddress',
						'subventionExistsOnAddress'
					].includes(String(input.name))
				) {
					if (input.value === '') {
						listErrors.push(input.name)

						if (input.ref && !isFirstErrorAlreadySet) {
							input.ref.current?.focus()
							isFirstErrorAlreadySet = true
						}
					} // validation of Date inputs
					else if (input.extraParams?.type == 'date') {
						var inputDate = new Date(input.value)
						var minDate = new Date(input.extraParams?.min!)
						var maxDate = new Date(input.extraParams?.max!)

						if (inputDate < minDate || inputDate > maxDate) {
							listErrors.push(input.name)
						}
					} else {
						configs[input.name] = input.value
					}
				}
			})

			let locationState = {
				useDefaultUserLocation,
				city,
				address,
				apartment,
				postalCode,
				cadastralAddress,
				coordinates: {
					longitude: coordinates?.longitude,
					latitude: coordinates?.latitude
				}
			}

			dispatch(populateRequestLocationState(locationState))
		}

		if (
			subSteps &&
			(subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_3 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_4 ||
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS)
		) {
			inputs.forEach((input: RequestInputs) => {
				// differentiate DETAILS inputs from DESCRIPTION inputs substep
				if (
					(subSteps.position == REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 &&
						input.subStep == undefined) ||
					subSteps.position == input.subStep
				) {
					// special case for plantedTreeList (value is a serialized array)
					switch (input.name) {
						case 'plantedTreeList':
							let plantedTreeList: PlantedTree[] = JSON.parse(input.value)

							let plantedTreeAttributes = [
								'Species',
								'Cost',
								'Location',
								'Planting_date'
							]

							plantedTreeList?.forEach((element, row) => {
								plantedTreeAttributes.forEach((attr) => {
									if (element[`${attr?.toLowerCase()}`] == '') {
										listErrors.push(`plantedtree${attr}${row}`)
									}
								})

								// if (element.specie == '') {
								// 	listErrors.push(`plantedtreeSpecie${row}`)
								// }
								// if (element.diameter == '') {
								// 	listErrors.push(`plantedtreeDiameter${row}`)
								// }
								// if (element.cost == '') {
								// 	listErrors.push(`plantedtreeCost${row}`)
								// }
								// if (element.location == '') {
								// 	listErrors.push(`plantedtreeLocation${row}`)
								// }
							})

							configs[input.name] = plantedTreeList
							break
						case 'pesticideCertificatesList':
							let pesticideCertificates: PesticideCertificates[] = JSON.parse(
								input.value
							)

							let pesticideCertificatesAttributes = [
								'Holder_name',
								'Certificat_number',
								'Category',
								'Issue_date',
								'Expiration_date'
							]

							pesticideCertificates?.forEach((element, row) => {
								pesticideCertificatesAttributes.forEach((attr) => {
									if (element[`${attr?.toLowerCase()}`] == '') {
										listErrors.push(`pesticideCertificates${attr}${row}`)
									}
								})
							})

							configs[input.name] = pesticideCertificates
							break
						case 'vehicleInformationList':
							let vehicleInformationList: VehicleInformation[] = JSON.parse(
								input.value
							)

							let vehicleInformationAttributes: string[] = []
							switch (request?.name) {
								case TNRequestNames.annualRegistrationCertificateForContractors:
									vehicleInformationAttributes = [
										'Brand',
										'Model',
										'Year',
										'Color',
										'Registration_number'
									]
									break
								case TNRequestNames.fireHydrantsTankFilling:
									vehicleInformationAttributes = [
										'Plate_number',
										'Tank_volume',
										'Frequency_of_use'
									]
									break
							}

							vehicleInformationList?.forEach((element, row) => {
								vehicleInformationAttributes.forEach((attr) => {
									if (element[`${attr?.toLowerCase()}`] == '') {
										listErrors.push(`vehicleInformation${attr}${row}`)
									}
								})
							})

							configs[input.name] = vehicleInformationList
							break
						case 'vehicleInformation':
							if (input.value) {
								let vehicleInformation: VehicleInformation = JSON.parse(
									input.value
								)

								let vehicleInformationProperties: string[] = [
									'Brand',
									'Model',
									'Color',
									'Registration_number'
								]

								input.required &&
									vehicleInformationProperties.forEach((attr) => {
										if (vehicleInformation[`${attr?.toLowerCase()}`] == '') {
											listErrors.push(`vehicleInformation${attr}`)
										}
									})

								configs[input.name] = vehicleInformation
							}
							break
						case 'dateTimeList':
							if (input.value?.trim() != '') {
								let dateTimeList: AdministrativeRiviewInfo[] = JSON.parse(
									input.value
								)
								const attributes: string[] = [
									'occpation_value',
									'start_time',
									'end_time'
								]

								dateTimeList?.forEach((element, row) => {
									attributes.forEach((attr) => {
										if (
											element[attr] == '' &&
											element['is_24_7'] == false &&
											input.required
										) {
											listErrors.push(`dateTime_${attr}${row}`)
										}
									})
								})
								configs[input.name] = dateTimeList
							}
							break
						case 'lotNumbersList':
							let lotNumbersList: string[] = JSON.parse(input.value)

							lotNumbersList?.forEach((element, index) => {
								if (element === '' || element.length < 7) {
									listErrors.push(`lotNumberValue${index}`)
								}
							})

							configs[input.name] = lotNumbersList
							break
						case 'parkingPermitList':
							const parkingPermitList: ParkingPermitInformation[] = JSON.parse(
								input.value
							)
							const parkingPermitAttributes = Object.keys(parkingPermitList[0])

							const requiredAttributesValue = inputs.filter(
								(element) => element.name == 'parkingRequiredAttributes'
							)[0].value
							const requiredAttributes = JSON.parse(requiredAttributesValue)

							parkingPermitList?.forEach((element, row) => {
								parkingPermitAttributes.forEach((attr) => {
									if (
										element[`${attr}`] == '' &&
										requiredAttributes.includes(
											`parkingPermitInfo_${attr}${row}`
										)
									) {
										listErrors.push(`parkingPermitInfo_${attr}${row}`)
									}
								})
							})
							configs[input.name] = parkingPermitList
							break
						case 'animalInformationList':
							let animalInformationList: AnimalInformation[] = JSON.parse(
								input.value
							)

							const animalInformationListAttributes = requiredFieldAttributes
							animalInformationList?.forEach((element, row) => {
								if (element['type'] == '') {
									listErrors.push(`animalInformation_type${row}`)
								} else {
									animalInformationListAttributes[row]?.forEach((attr) => {
										if (element[`${attr}`] == '') {
											listErrors.push(`animalInformation_${attr}${row}`)
										}
									})
								}
							})

							configs[input.name] = animalInformationList
							break
						case 'animalRequiredFileAttributes':
							let animalRequiredFileAttributes: AnimalRequiredFileAttributes[] =
								JSON.parse(input.value)

							configs[input.name] = animalRequiredFileAttributes
							break
						case 'animalAttributes':
							let animalAttributes = JSON.parse(input.value)

							configs[input.name] = animalAttributes
							break
						case 'vehicleInformation':
							if (input.value) {
								let vehicleInformation: VehicleInformation = JSON.parse(
									input.value
								)

								let vehicleInformationProperties: string[] = [
									'Brand',
									'Model',
									'Color',
									'Registration_number'
								]

								input.required &&
									vehicleInformationProperties.forEach((attr) => {
										if (vehicleInformation[`${attr?.toLowerCase()}`] == '') {
											listErrors.push(`vehicleInformation${attr}`)
										}
									})

								configs[input.name] = vehicleInformation
							}
							break
						case 'isNewRequest':
							const permitNumber = inputs.find(
								(input) => input.name == 'permitNumber'
							)
							const requestNumber = inputs.find(
								(input) => input.name == 'requestNumber'
							)
							if (
								permitNumber?.value == '' &&
								requestNumber?.value == '' &&
								input.value == REQUEST_CONFIGS_KEYS.requestType.modification
							) {
								listErrors.push(permitNumber.name)
								listErrors.push(requestNumber.name)
							}

							if (input.value == '') {
								listErrors.push(input.name)
							}

							configs[input.name] = input.value
							break
						default:
							// the usual validation cases
							if (input.required && input.value === '') {
								listErrors.push(input.name)

								if (
									input.ref &&
									// check to prevent TypeError: _.focus is not a function
									input.ref?.current instanceof HTMLElement &&
									!isFirstErrorAlreadySet
								) {
									input.ref?.current?.focus()
									isFirstErrorAlreadySet = true
								}
							}

							if (
								input.required &&
								REQUEST_CONFIG_KEY_RELATION[input.name] === 'number' &&
								Number.isNaN(Number(input.value))
							) {
								listErrors.push(input.name)
							}

							if (!input.required && input.value !== '') {
								// details += `${input.label} ${input.value}\r`

								metadata.push({
									label: input.labelKey,
									value: `${input.valueKey ? input.valueKey : input.value}`
								})
							}

							// validation of Date inputs
							if (input.value !== '' && input.extraParams?.type == 'date') {
								var inputDate = new Date(input.value)
								var minDate = new Date(input.extraParams?.min!)
								var maxDate = new Date(input.extraParams?.max!)

								if (inputDate < minDate || inputDate > maxDate) {
									listErrors.push(input.name)
								}
							}

							if (
								input.value !== '' &&
								!config.request.create.fieldsToExcludeFromConfig.includes(
									input.name
								)
							) {
								let configsInputValue: number | string | boolean = input.value

								switch (REQUEST_CONFIG_KEY_RELATION[input.name]) {
									case 'number':
										configsInputValue = convertStringToNumber(configsInputValue)
										break
									case 'boolean':
										configsInputValue =
											String(configsInputValue)?.toLowerCase() === 'true'
										break
									default:
										break
								}

								configs[input.name] = configsInputValue

								if (input.labelKey !== '') {
									metadata.push({
										label: input.labelKey,
										value: `${input.valueKey ? input.valueKey : input.value}`,
										required: true
									})
								}
							}

							if (
								!input.required &&
								input.value == '' &&
								answers.hasOwnProperty(input.name)
							) {
								configs[input.name] = undefined
							}
							break
					}
				} else if (
					(subSteps.position == REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2 &&
						input.subStep == undefined) ||
					subSteps.position == input.subStep
				) {
					switch (input.name) {
						case 'hydraulicTestList':
							let hydraulicTestList: string[] = JSON.parse(input.value)
							configs[input.name] = hydraulicTestList
							break
						default:
							break
					}
				}
			})
		}

		dispatch(populateRequestConfigsState(configs))

		// Verify if a document exists within the sub steps apart from the document step
		const subStepHasDocument = inputFiles.find(
			(input: RequestInputFiles) =>
				input.subStep === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS
		)

		if (
			(subSteps &&
				subSteps.position === REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS) ||
			!!subStepHasDocument
		) {
			const cleanedFiles: RequestInputFiles[] = []

			inputFiles.forEach((input: RequestInputFiles) => {
				// Les documents qui doivent être validés dans une sous-étape doivent être définis dans la variable shouldValidateIn.
				const fileIsEmpty = !!input.separateFiles
					? Object.keys(input.separateFiles).length === 0
					: input.files.size === 0
				const hasError = input.shouldValidateIn
					? subSteps &&
					  subSteps.position == input.shouldValidateIn &&
					  input.required
					: input.required

				if (hasError && fileIsEmpty) {
					listErrors.push(input.name)
				}
				// clean deleted files of non required files
				if (!input.required && input.files.size == 0) {
					let config = { ...configs }

					config[input.name] = {}
					dispatch(populateRequestConfigsState(config))
				}

				if (input.name === 'animalInformationFileList') {
					const animalRequiredFileAttributes = requiredFileAttributes

					// get animalName from input list
					let animals = inputs.find(
						(input) => input.name === 'animalInformationList'
					)
					let animalInformationList: AnimalInformation[] = JSON.parse(
						animals?.value!
					)

					input.files?.forEach((element, row: number) => {
						let attr = animalRequiredFileAttributes[row]

						!!attr &&
							Object.keys(attr).forEach((key) => {
								if (
									attr[key] == true &&
									(element[`${key}`]?.length == 0 ||
										element[`${key}`]?.size == 0) &&
									subSteps?.position !== REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1
								) {
									listErrors.push(`animalInformation_${key}${row}`)
								}

								if (
									element[`${key}`]?.length > 0 ||
									element[`${key}`]?.size > 0
								) {
									let fileName =
										`${animalInformationList[row].name} #${row + 1} - ` +
										`${annualPermitAndTagForAnimalsFileNames[key]}`
									let fileInput: RequestInputFiles = {
										name: `animalInformation_${key}${row}Input`,
										label: fileName,
										description: fileName,
										files: element[`${key}`],
										labelKey: '',
										required: true,
										category: requestCategories.other
									}

									cleanedFiles.push(fileInput)

									saveFileToStore(fileInput, configs).then(() => {
										dispatch(populateRequestConfigsState(configs))
									})
								}
							})
					})
				}

				if (
					input.name == 'parkingPermitFileList' &&
					subSteps?.position == REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_2
				) {
					const parkingPermitFileList = input.files

					const parkingPermitAttributes = Object.keys(parkingPermitFileList[0])

					const requiredAttributesValue = inputs.filter(
						(element) => element.name == 'parkingRequiredAttributes'
					)[0].value
					const requiredAttributes = JSON.parse(requiredAttributesValue)

					// get parkingPermit from input list
					let parkingPermit = inputs.find(
						(input) => input.name === 'parkingPermitList'
					)
					let parkingPermitList: ParkingPermitInformation[] = JSON.parse(
						parkingPermit?.value!
					)

					input.files?.forEach((element, row: number) => {
						Object.keys(element).forEach((key) => {
							parkingPermitAttributes.forEach((attr) => {
								if (
									element[`${attr}`].length == 0 &&
									requiredAttributes.includes(`parkingPermitInfo_${attr}${row}`)
								) {
									listErrors.push(`parkingPermitInfo_${attr}${row}`)
								}
							})

							if (
								element[`${key}`]?.length > 0 ||
								element[`${key}`]?.size > 0
							) {
								let fileName =
									`${parkingPermitList[row]?.vehicle_plate_number} #${
										row + 1
									} - ` + `${parkingPermitFileNames[key]}`
								let fileInput: RequestInputFiles = {
									name: `parkingPermitInfo_${key}${row}`,
									label: fileName,
									description: fileName,
									files: element[`${key}`],
									labelKey: '',
									required: true,
									category: requestCategories.other
								}

								cleanedFiles.push(fileInput)

								saveFileToStore(fileInput, configs).then(() => {
									dispatch(populateRequestConfigsState(configs))
								})
							}
						})
					})
				}

				if (
					input.files.size > 0 ||
					(!!input.separateFiles && Object.keys(input.separateFiles).length > 0)
				) {
					if (
						input.numberFilesRequired &&
						(!!input.separateFiles
							? Object.keys(input.separateFiles).length <
							  input.numberFilesRequired
							: input.files.size < input.numberFilesRequired)
					) {
						listErrors.push(input.name)
					}

					if (!!input.separateFiles) {
						Object.keys(input.separateFiles).forEach((key) => {
							if (input.separateFiles![key].size == 0)
								listErrors.push(input.name)
						})
					}

					if (input.namingValidation) {
						input.files.forEach((currentFile) => {
							if (!currentFile?.name.includes(`${input.namingValidation}`)) {
								listErrors.push(input.name)
							}
						})
					}

					cleanedFiles.push(input)

					// processing/mapping files then dispatch configs to store
					if (!!input.separateFiles) {
						// inputs with SeparetedFiles
						saveSeparetedFileToStore(input, configs).then(() => {
							dispatch(populateRequestConfigsState(configs))
						})
					} else {
						// other inputs
						saveFileToStore(input, configs).then(() => {
							dispatch(populateRequestConfigsState(configs))
						})
					}
				}
			})

			setFilesInputs(cleanedFiles)
		}

		dispatch(
			populateRequestModelState({
				description: details,
				metadata
			})
		)

		setErrors(listErrors)

		return listErrors.length === 0
	}

	const goToNextStep = () => {
		if (subSteps && currentStep === steps.form) {
			const index: number = subSteps.steps.indexOf(subSteps.position)

			if (index >= 0 && (!processingDataAndValidation() || customError)) {
				return
			}

			if (index >= 0 && index < subSteps.steps.length - 1) {
				setSubSteps({
					...subSteps,
					position: REQUEST_TN_DETAILS_STEPS_NAME[subSteps.steps[index + 1]]
				})

				return
			}
		}

		if (currentStep >= 1 && currentStep < config.request.create.nbPDFSteps) {
			setCurrentStep(currentStep + 1)
		}
	}

	const goToPreviousStep = () => {
		if (subSteps && currentStep === steps.form) {
			const index: number = subSteps.steps.indexOf(subSteps.position)
			// Special case for tree planting
			if (pageId == pageUtils.FORMS_ID.TREE_PLANTING_FORM) {
				processingDataAndValidation()
			}

			if (index > 0) {
				setSubSteps({
					...subSteps,
					position: REQUEST_TN_DETAILS_STEPS_NAME[subSteps.steps[index - 1]]
				})

				setErrors([])

				return
			}
		}

		if (currentStep > 1 && currentStep <= config.request.create.nbPDFSteps) {
			setCurrentStep(currentStep - 1)

			setErrors([])
		}
	}

	useEffect(() => {
		const param = new URLSearchParams(location?.search)
		const step = param.get(URL_PARAMS.STEP)
		if (step) {
			setCurrentStep(parseInt(step))
		}
	}, [])

	useEffect(() => {
		const currentLocation = location.href
		const preventFromLeaving = (e) => {
			e.preventDefault()

			var r = confirm(pageAssets?.createRequest_page_do_you_want_to_leave)
			if (r == true) {
				history.back()
			} else {
				navigate(currentLocation)
				history.replaceState(null, '', currentLocation)
			}
		}

		window.addEventListener('popstate', preventFromLeaving)
		return () => {
			window.removeEventListener('popstate', preventFromLeaving)
		}
	}, [])

	useEffect(() => {
		if (!!eligibilityRequirement) {
			const divs = document.getElementsByClassName(
				`${ACCORDION_DESCRIPTION.value}`
			)
			Array.prototype.forEach.call(divs, (div: HTMLDivElement) => {
				const links = div.getElementsByTagName('a')

				Array.prototype.forEach.call(links, (link) => {
					const attribute = link.href.split('Pages')
					if (attribute.length == 2) {
						if (attribute[0].includes('Documents')) {
							link.href = 'https://www.laval.ca/Documents/Pages' + attribute[1]
						} else {
							link.href = 'https://www.laval.ca/Pages' + attribute[1]
						}
						link.target = '_blank'
					}
				})

				const images = div.getElementsByTagName('img')
				Array.prototype.forEach.call(images, (image) => {
					let attribute
					if (image.src.includes('PublishingImages')) {
						attribute = image.src.split('PublishingImages')
						image.src = 'https://www.laval.ca/PublishingImages' + attribute[1]
					} else {
						attribute = image.src.split('_layouts')
						image.src = 'https://www.laval.ca/_layouts' + attribute[1]
					}
				})
			})
		}
	}, [eligibilityRequirement])

	useEffect(() => {
		if (!eligibilityRequirement?.length) {
			const timer = setTimeout(() => {
				setIsEligibilityAvailable(false)
			}, 10000)

			return () => clearTimeout(timer)
		}
	}, [eligibilityRequirement])

	useEffect(() => {
		if (isBeforeStartingStepRequired() && request?.name !== undefined) {
			//  No next step function since there is no eligibility requirement page
			validateAddressBeforeNextStep(() => {}, setOpenValidationModal)
		}
	}, [])

	const getEligibilityRequirementInfo = (): string => {
		const text =
			pageAssets?.request_form_information_is_not_available_at_the_moment
		const eligibilityUrl = `<a href="${pageUrlDic[id]?.url}">${pageUrlDic[id]?.url}</a>`
		return text + eligibilityUrl
	}

	const renderRequestFailedModal = () => {
		return (
			<RequestFailedModal
				requestHasError={requestHasError}
				setRequestHasError={setRequestHasError}
				onResetRequestFailed={onResetRequestFailed}
				closeLink={closeLink}
				setDoSentPost={setDoSentPost}
			/>
		)
	}

	const isBreadcrumbRequired = (): boolean => {
		const requestTypes = [
			TNRequestNames.temporaryPesticideApplicationPermitForIndividuals,
			TNRequestNames.oilHeatingDeclaration,
			TNRequestNames.claimNotice,
			TNRequestNames.annualRegistrationCertificateForContractors,
			TNRequestNames.historicalArchive,
			TNRequestNames.selfReliefWaterCounter,
			TNRequestNames.buildingTransactionRequest,
			TNRequestNames.hydraulicAndPressureTests,
			TNRequestNames.fireHydrantsTempAqueductNetwork,
			TNRequestNames.fireHydrantsTankFilling,
			TNRequestNames.municipalEvaluation,
			TNRequestNames.annualPermitAndTagForAnimals,
			TNRequestNames.fireplaceDeclaration,
			TNRequestNames.parkingPermit,
			TNRequestNames.permitOccupationOfPublicHighway,
			TNRequestNames.sustainableHygieneProducts,
			TNRequestNames.carSharing,
			TNRequestNames.bikeSharing,
			TNRequestNames.fireplaceGrant,
			TNRequestNames.swimmingPoolSpaPermit,
			TNRequestNames.wateringPermit
		]
		return !requestTypes.includes(request?.name ?? '')
	}

	const isBeforeStartingStepRequired = (): boolean => {
		const requestTypes = [
			TNRequestNames.oilHeatingDeclaration,
			TNRequestNames.selfReliefWaterCounter,
			TNRequestNames.fireplaceDeclaration
		]
		return !requestTypes.includes(request?.name ?? '')
	}

	const showStepNumber = (): boolean => {
		const requestTypes = [
			TNRequestNames.temporaryPesticideApplicationPermitForIndividuals,
			TNRequestNames.hydraulicAndPressureTests,
			TNRequestNames.fireHydrantsTempAqueductNetwork,
			TNRequestNames.annualPermitAndTagForAnimals,
			TNRequestNames.permitOccupationOfPublicHighway,
			TNRequestNames.fireplaceGrant
		]
		return requestTypes.includes(request?.name ?? '')
	}

	const showRequestConfirmationWrapper = (): boolean => {
		let requestTypes = [
			// Poteaux d'incendie
			TNRequestNames.fireHydrantsTankFilling,
			TNRequestNames.fireHydrantsTempAqueductNetwork,
			TNRequestNames.hydraulicAndPressureTests,
			// Subvention Mazout
			TNRequestNames.replacementOilHeatingSystem,
			TNRequestNames.fireplaceDeclaration
		]

		if (
			[
				REQUEST_CONFIGS_KEYS.claimNature.bodilyInjury,
				REQUEST_CONFIGS_KEYS.claimNature.roadWeatheringDamage
			].includes(answers.claimNature?.toString()!)
		) {
			requestTypes.push(TNRequestNames.claimNotice)
		}

		return requestTypes.includes(request?.name ?? '')
	}

	const setProfileRedirectTab = (tab: TAB) => {
		localStorage.setItem(config.localStorage.activeTab, tab)
	}

	const getCloseLink = (): string => {
		switch (request?.name) {
			case TNRequestNames.claimNotice:
				return config.homepage.baseURL
			case TNRequestNames.municipalEvaluation:
				setProfileRedirectTab(TAB.PROPERTY)
				return config.auth.profile.baseUrl
			case TNRequestNames.oilHeatingDeclaration:
			case TNRequestNames.selfReliefWaterCounter:
				return config.auth.profile.baseUrl
			default:
				return closeLink
		}
	}

	const validateAddressBeforeNextStep = async (
		callbacK: () => void,
		setOpenModal: (value: React.SetStateAction<boolean>) => void
	): Promise<boolean> => {
		let isValid = true
		if (
			!authUser?.profile?.address.inMunicipality ||
			!authUser?.profile?.address.hasAddressEnteredManually
		) {
			callbacK()
			return isValid
		}
		setIsValidating(true)
		const { data } = await getDefaultCadastralNumber(
			authUser?.profile?.address.address
		)

		if (data.features[0].place_name !== authUser?.profile?.address.address) {
			setProposedAddress(data.features[0])
			setOpenModal(true)
			isValid = false
		}
		setIsValidating(false)
		callbacK()
		return isValid
	}

	const updateUserAddress = async (
		updateRequestAdress?: boolean,
		isFromMap?: boolean
	) => {
		contact.address.address = isFromMap
			? mapAddress
			: proposedAddress.place_name
		contact.address.postalCode = isFromMap
			? mapPostalCode
			: proposedAddress?.properties.postal_code
		contact.address.coordinates = isFromMap
			? (mapCoordinates as AddressCoordinates)
			: contact.address.coordinates
		if (!account) {
			// we must have an account, otherwise, we're not logged in and we should not be able to access this page.
			navigate(config.homepage.baseURL)

			throw new Error(
				'No account was found to perform a contact update for the user'
			)
		}
		try {
			setIsUpdatingAddress(true)

			contact.address.prepareProperties()
			contact.applyAccountValues(account)

			await contact.saveAsync()
			const user: User = await User.buildAsync(account, contact)

			setAuthUser(user)
		} catch (error: any) {
			setIsUpdatingAddress(false)
			alert(error?.message)
			console.error('Backend Error: ', error?.message)

			throw new Error(error?.message)
		} finally {
			setIsUpdatingAddress(false)
			setOpenValidationModal(false)
			goToNextStep()
			if (updateRequestAdress) {
				updateAddressInput(isFromMap)
				isFromMap && setShouldMapDisplay(false)
			}
		}
	}

	const updateAddressInput = (isFromMap?: boolean) => {
		let locationState = {
			useDefaultUserLocation,
			city: authUser?.profile?.address.city,
			address: isFromMap ? mapAddress : proposedAddress.place_name,
			postalCode: isFromMap
				? mapPostalCode
				: proposedAddress.properties.postal_code,
			cadastralAddress: authUser?.profile?.address.cadastralAddress,
			coordinates: {
				longitude: isFromMap
					? mapCoordinates?.longitude
					: proposedAddress.geometry.coordinates[0],
				latitude: isFromMap
					? mapCoordinates?.latitude
					: proposedAddress.geometry.coordinates[1]
			},
			hasAddressEnteredManually: false
		}

		dispatch(populateRequestLocationState(locationState))
		setShouldValidateAddress(false)
	}

	const renderAddressValidation = (
		openModal: boolean,
		setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		return (
			<AddressValidationModal
				currentAddress={{
					address: authUser?.profile?.address.address,
					city: authUser?.profile?.address.city,
					postalCode: authUser?.profile?.address.postalCode
				}}
				proposedAddress={proposedAddress}
				isModalOpen={openModal}
				onCancelBtn={() => {
					setShouldMapDisplay(false)
				}}
				onCloseModal={() => {
					setShouldMapDisplay(true)
				}}
				onPrimaryButton={() => {
					updateUserAddress(true)
				}}
				onPrimaryBtnMap={() => {
					updateUserAddress(true, true)
				}}
				onCancelBtnMap={() => {
					setShouldMapDisplay(false)
					setMapCoordinates(undefined)
					setOpenModal(false)
				}}
				shouldMapDisplay={shouldMapDisplay}
				onRetreiveCoordinates={setMapCoordinates}
				onSetPostalCode={setMapPostalCode}
				onSetCity={setMapCity}
				onAddressInputChange={setMapAddress}
				isPrimaryBtnLoading={isUpdatingAddress}
			/>
		)
	}

	const getAcceptedTermsAndConditions = (request?: Request) => {
		switch (request?.name) {
			case TNRequestNames.temporaryPesticideApplicationPermitForIndividuals:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_pesticide
			case TNRequestNames.hydraulicAndPressureTests:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_hydraulic_test
			case TNRequestNames.fireHydrantsTempAqueductNetwork:
			case TNRequestNames.fireHydrantsTankFilling:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_aqueduct_network
			case TNRequestNames.annualPermitAndTagForAnimals:
			case TNRequestNames.swimmingPoolSpaPermit:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_annualPermitAndTagForAnimals
			case TNRequestNames.parkingPermit:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_parkingPermit
			case TNRequestNames.municipalEvaluation:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_municipal_eval
			case TNRequestNames.permitOccupationOfPublicHighway:
				return pageAssets?.createRequest_page_accept_terms_and_conditions_for_annualPermitAndTagForAnimals
			default:
				return pageAssets?.createRequest_page_accept_terms_and_conditions
		}
	}

	const getPageRequestInfoTitle = () => {
		if (isDeclarationRequest(request?.name!)) {
			return pageAssets?.page_requestInfo_declaration_informations
		}

		return pageAssets?.page_requestInfo_request_informations
	}

	const getPageRequestInfo = () => {
		switch (request?.name) {
			case TNRequestNames.historicalArchive:
			case TNRequestNames.buildingTransactionRequest:
			case TNRequestNames.hydraulicAndPressureTests:
			case TNRequestNames.fireHydrantsTempAqueductNetwork:
			case TNRequestNames.fireHydrantsTankFilling:
			case TNRequestNames.annualRegistrationCertificateForContractors:
			case TNRequestNames.temporaryPesticideApplicationPermitForIndividuals:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition
					.replace(' de subvention', '')
					.replace('grant', '')
			case TNRequestNames.annualPermitAndTagForAnimals:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition_annualPermitAndTagForAnimals
			case TNRequestNames.claimNotice:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition_claim_notice
			case TNRequestNames.permitOccupationOfPublicHighway:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition_occupation_of_public_hightway
			case TNRequestNames.municipalEvaluation:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition_municipal_evaluation
			case TNRequestNames.swimmingPoolSpaPermit:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition_certificate
			default:
				return pageAssets?.page_requestInfo_have_read_eligibility_condition
		}
	}

	// const requestHasPayment = (): boolean => {
	// 	const requestTypes = [
	// 		TNRequestNames.annualPermitAndTagForAnimals,
	// 		TNRequestNames.parkingPermit
	// 	]
	// 	return requestTypes.includes(request?.name ?? '')
	// }

	const renderPolicyAndSubmitBtn = () => {
		return (
			<>
				<div className={pageUtils.classes.checkbox2Container}>
					<form>
						<input
							onClick={(e: any) => {
								setConditionsAccepted(e.target.checked)
							}}
							value={`${conditionsAccepted}`}
							className={pageUtils.classes.checkbox2}
							type="checkbox"
							name="terms_and_condition"
							id="terms_and_condition"
							disabled={loading}
						/>
						<label
							className={pageUtils.classes.checkbox2Label}
							htmlFor="terms_and_condition"
						>
							{getAcceptedTermsAndConditions(request)}
							<RequiredIcon />
						</label>
					</form>
				</div>

				<div className={pageUtils.classes.submitContainer}>
					<div className={pageUtils.classes.submitRoot}>
						<button
							className={joinClasses([
								pageUtils.classes.btn,
								pageUtils.classes.button,
								pageUtils.classes.buttonOutline,
								loading ? pageUtils.classes.btnDisabled : ''
							])}
							onClick={() => goToPreviousStep()}
							style={{
								marginRight: '10px',
								marginLeft: '0px'
							}}
						>
							{formatStrapiText(pageAssets?.button_previous)}
						</button>
						<button
							type="button"
							className={joinClasses([
								pageUtils.classes.submitBtn,
								pageUtils.classes.btn,
								loading ||
								(hasFileError && hasFileRequiredError) ||
								!conditionsAccepted
									? pageUtils.classes.btnDisabled
									: ''
							])}
							onClick={onSubmit(processingData, errors.length > 0)}
						>
							{!loading && (
								<Text
									content={
										// requestHasPayment()
										// 	? pageAssets?.btn_submit
										// 	:
										pageAssets?.page_requestInfo_inputSubmit
									}
								/>
							)}
							{loading && (
								<div className={`${pageUtils.classes.loader} spin`} />
							)}
						</button>
					</div>
					{loading && (
						<span className={pageUtils.classes.loadingMessage}>
							{pageAssets?.createRequest_page_wait_while_we_submit_your_request}
						</span>
					)}
				</div>
			</>
		)
	}

	useEffect(() => {
		if (isRequestHasPayementOnDepot()) {
			if (currentStep === 5) {
				let tempList = [...listSteps]
				tempList[4] = pageAssets?.page_requestInfo_step_confirmation_and_payment
				setStepDescription(tempList)
			} else setStepDescription(listSteps)
		} else {
			setStepDescription(listSteps)
		}

		setConditionsAccepted(false)
	}, [currentStep])

	if (
		request?.name == TNRequestNames.publicAuctions &&
		currentStep !== steps.confirmation
	) {
		return (
			<PublicAuctionsForm
				request={request}
				closeLink={closeLink}
				files={extraFiles}
				hasFileError={hasFileError}
				hasFileRequiredError={hasFileRequiredError}
				inputs={inputs}
				errors={errors}
				inputFiles={inputFiles}
				onSetInputFiles={setInputFiles}
				onFixError={setErrors}
				onSetFiles={setExtraFiles}
				onSetHasFileError={setHasFileError}
				onSetHasFileRequiredError={setHasFileRequiredError}
				onSetInputs={setInputs}
				onSetFilesInputs={setFilesInputs}
				subSteps={subSteps}
				onSetSubSteps={setSubSteps}
				filesInputs={filesInputs}
				onCustomError={setCustomError}
				steps={steps}
				formPosition={currentStep}
				setCurrentStep={setCurrentStep}
				loading={loading}
				onProcessingData={processingData}
				renderRequestFailedModal={renderRequestFailedModal}
			></PublicAuctionsForm>
		)
	}

	return (
		<div
			className={joinClasses([
				pageUtils.classes.root,
				browser,
				language === LanguageEnum.EN ? pageUtils.classes.withCompactFooter : ''
			])}
		>
			<div className={pageUtils.classes.contentPdf}>
				<PageTitle title={title} />

				<div className={pageUtils.classes.sectionTitle}>
					<SectionTitle
						title={title}
						subTitle={request?.title}
						showBreadcrumbs={false}
						link={getCloseLink()}
						classRoot={joinClasses([
							pageUtils.classes.sectionTitleRoot,
							pageUtils.classes.sectionTitleRootLowPadding
						])}
						showCharterInfo={false}
					/>
				</div>

				{request?.name !== TNRequestNames.publicAuctions && (
					<div className={pageUtils.classes.progressBar}>
						<ProgressBar
							steps={stepDescription}
							currentStep={
								isBeforeStartingStepRequired() ? currentStep : currentStep - 1
							}
						/>
					</div>
				)}

				{currentStep === steps.start && (
					<section
						className={joinClasses([
							pageUtils.classes.container,
							pageUtils.classes.noPadding,
							pageUtils.classes.noBackground
						])}
					>
						<div className={pageUtils.classes.sectionBlueTitle}>
							<h3>{formatStrapiText(pageAssets?.page_requestInfo_step1)}</h3>
						</div>

						<div className={pageUtils.classes.textSection}>
							{isEligibilityAvailable && (
								<>
									{eligibilityRequirement ? (
										<>
											<div className={pageUtils.classes.headerWrapper}>
												<p>
													{' '}
													<Text content={getPageRequestInfo()} />
												</p>
											</div>

											<EligibilityRequirement
												requirements={eligibilityRequirement}
												pageId={pageId}
											/>
										</>
									) : (
										<>
											<Loader text={pageAssets?.loading} />
										</>
									)}
								</>
							)}

							<Collapse isOpened={!isEligibilityAvailable ?? false}>
								<Notification
									type="info"
									hasHtml
									externalIcon
									text={getEligibilityRequirementInfo()}
								/>
							</Collapse>
						</div>

						<div className={pageUtils.classes.buttonContainer}>
							<button
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.submitBtn,
									pageUtils.classes.button,
									isValidating ? pageUtils.classes.btnDisabled : ''
								])}
								onClick={() =>
									validateAddressBeforeNextStep(
										goToNextStep,
										setOpenValidationModal
									)
								}
							>
								{!isValidating ? (
									formatStrapiText(pageAssets?.button_start)
								) : (
									<div className={`${pageUtils.classes.loader} spin`} />
								)}
							</button>
						</div>
					</section>
				)}

				{currentStep === steps.applicant && (
					<>
						<section
							className={joinClasses([
								pageUtils.classes.container,
								pageUtils.classes.noPadding,
								pageUtils.classes.noBackground
							])}
						>
							<div className={pageUtils.classes.sectionBlueTitle}>
								<h3>
									{formatStrapiText(
										pageAssets?.request_form_personal_informations
									)}
								</h3>
							</div>

							<div className={pageUtils.classes.textSection}>
								<p style={{ marginTop: '0px' }}>
									{pageAssets?.request_form_confirm_personal_information}{' '}
									<span
										className={pageUtils.classes.buttonLink}
										onClick={() => setIsOpenModal(true)}
									>
										{formatStrapiText(
											pageAssets?.request_form_change_personal_information
										)}
									</span>
								</p>

								<div className={pageUtils.classes.lineDivider}>
									<div className={pageUtils.classes.applicantInformation}>
										<div className={pageUtils.classes.infoTitle}>
											<strong>{pageAssets?.label_lastName}</strong>
											<br />
											{authUser?.profile?.lastName}{' '}
											{authUser?.profile?.firstName}
										</div>
										<div className={pageUtils.classes.infoTitle}>
											<strong>{pageAssets?.label_email}</strong>
											<br />
											{authUser?.profile?.email}
										</div>
										<div className={pageUtils.classes.infoTitle}>
											<strong>{pageAssets?.label_address}</strong>
											<br />
											{authUser?.profile?.address.address}
											<br />
											{authUser?.profile?.address.city}
											{authUser?.profile?.address.state
												? ` (${authUser?.profile?.address.state}) `
												: ' '}
											{authUser?.profile?.address.postalCode}
											<br />
											{authUser?.profile?.address.apartment
												? `${formatStrapiText(
														pageAssets?.label_apartment_office
												  )} ${authUser?.profile?.address.apartment}`
												: ''}
										</div>
										<div className={pageUtils.classes.infoTitle}>
											<strong>{pageAssets?.label_telephone}</strong>
											<br />
											{authUser?.profile?.phoneNumber ||
												authUser?.profile?.phoneNumberEvening ||
												authUser?.profile?.phoneNumberOther}
										</div>
									</div>
								</div>
							</div>
							<div className={pageUtils.classes.buttonContainer}>
								{isBeforeStartingStepRequired() && (
									<button
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() => goToPreviousStep()}
									>
										{formatStrapiText(pageAssets?.button_previous)}
									</button>
								)}
								<button
									className={joinClasses([
										pageUtils.classes.btn,
										pageUtils.classes.button
									])}
									onClick={() => {
										if (shouldValidateAddress) {
											setShouldValidateAddress(false)
										}

										if (!authUser) {
											return
										}

										// verifier si adresse est en dehors de Laval (no coordinates)
										// Applicant step
										if (
											[
												TNRequestNames.annualPermitAndTagForAnimals,
												TNRequestNames.clothDiapers
											].includes(request?.name!)
										) {
											const coordinates: AddressCoordinates =
												new AddressCoordinates()
											coordinates.update(
												authUser?.profile?.address.coordinates!
											)

											if (!coordinates.validate()) {
												setIsAddressOutsideLavalMessageShow(true)
												return
											}
										}

										goToNextStep()
									}}
									style={{ marginLeft: '5px' }}
								>
									{formatStrapiText(pageAssets?.button_save_and_continue)}
								</button>
							</div>
							<AddressValidationModal
								currentAddress={{
									address: authUser?.profile?.address.address,
									city: authUser?.profile?.address.city,
									postalCode: authUser?.profile?.address.postalCode
								}}
								proposedAddress={proposedAddress}
								isModalOpen={openValidationModal}
								onCancelBtn={() => {}}
								onCloseModal={() => {
									goToNextStep()
									setOpenValidationModal(false)
									setShouldValidateAddress(false)
								}}
								onPrimaryButton={() => {
									updateUserAddress()
								}}
								isPrimaryBtnLoading={isUpdatingAddress}
							/>

							<Modal
								isOpen={isAddressOutsideLavalMessageShow}
								type={'warning'}
								// openUsingSetTimeout
								// cancelButtonText={pageAssets?.request_form_understood}
								onSetIsOpen={setIsAddressOutsideLavalMessageShow}
								cancelButtonText={pageAssets?.label_return_to_form}
								primaryButtonText={pageAssets?.label_quit}
								onPrimaryButton={() => {
									if (closeLink) {
										navigate(closeLink)
									}
								}}
								alignBtn={'center'}
							>
								<div className={pageUtils.classes.modalContent}>
									{
										pageAssets?.request_form_isAddressOutsideLavalMessageShow_forApplicant_notification_label
									}{' '}
									<span
										className={pageUtils.classes.buttonLink}
										onClick={() => {
											localStorage.setItem(config.localStorage.activeTab, '0')
											navigate(`/auth/profile`, {
												state: {
													form: true,
													url: location.pathname,
													step: 0
												}
											})
										}}
									>
										{formatStrapiText(
											pageAssets?.request_form_change_personal_information
										)}
									</span>
								</div>
							</Modal>
						</section>
						<ProfilePageRedirectModal
							isOpenModal={isOpenModal}
							setIsOpenModal={setIsOpenModal}
						/>
					</>
				)}

				{currentStep === steps.form && (
					<form
						autoComplete="off"
						className={joinClasses([
							pageUtils.classes.container,
							pageUtils.classes.pdfFormcontainer,
							pageUtils.classes.noPadding
						])}
						onSubmit={
							// Prevents the form from submitting if the ENTER key is pressed
							(e) => {
								e.preventDefault()
							}
						}
					>
						<div
							className={joinClasses([
								pageUtils.classes.sectionBlueTitle,
								pageUtils.classes.noMarginBottom
							])}
						>
							<h3>
								{formatStrapiText(
									showStepNumber()
										? `${getPageRequestInfoTitle()} (${pageUtils.getStepNumber(
												subSteps
										  )} ${pageAssets.label_of} ${subSteps?.steps.length})`
										: getPageRequestInfoTitle()
								)}
							</h3>

							{isBreadcrumbRequired() && (
								<div className={pageUtils.classes.breadcrumb}>
									{subSteps?.map && (
										<span
											className={
												REQUEST_TN_DETAILS_STEPS_NAME.MAP === subSteps.position
													? ''
													: pageUtils.classes.secondaryColor
											}
										>
											{mapLabel}
										</span>
									)}{' '}
									{subSteps?.map &&
										(subSteps?.details_1 || subSteps?.requiredDocument) && (
											<Icon
												className={pageUtils.classes.shevronIcon}
												src={chevronLightBlueIcon}
											/>
										)}
									{subSteps?.details_1 && (
										<span
											className={
												REQUEST_TN_DETAILS_STEPS_NAME.DETAILS_1 ===
												subSteps.position
													? ''
													: pageUtils.classes.secondaryColor
											}
										>
											{pageAssets?.request_form_grant_details}
										</span>
									)}{' '}
									{subSteps?.details_1 && subSteps?.requiredDocument && (
										<Icon
											className={pageUtils.classes.shevronIcon}
											src={chevronLightBlueIcon}
										/>
									)}
									{subSteps?.requiredDocument && (
										<span
											className={
												REQUEST_TN_DETAILS_STEPS_NAME.DOCUMENTS ===
												subSteps.position
													? ''
													: pageUtils.classes.secondaryColor
											}
										>
											{pageAssets?.request_form_required_documents}
										</span>
									)}
								</div>
							)}
						</div>

						<Collapse
							isOpened={
								subSteps !== undefined &&
								REQUEST_TN_DETAILS_STEPS_NAME.MAP === subSteps?.position
							}
						>
							<section className={pageUtils.classes.section}>
								<MapSelection
									form={request?.name}
									errors={errors}
									inputs={inputs}
									onSetInputs={setInputs}
									showButtonUserLocation={isAuthUserInLaval}
									hasError={errors.includes(AddressInputName)}
									onFixError={setErrors}
									onAddressInputChange={setAddress}
									onRetreiveCoordinates={setCoordinates}
									onSetCadastralAddress={setCadastralAddress}
									onApartmentInputChange={setApartment}
									onAddError={onAddErrorFromAddress}
									setIsRequired={setIsAddressRequired}
									onSetCity={setCity}
									setMapLabel={setMapLabel}
									onSetPostalCode={setPostalCode}
									setUseDefaultUserLocation={setUseDefaultUserLocation}
									setDisableNextButton={setDisableNextButton}
									closeLink={getCloseLink()}
									setShouldCheckAddress={setShouldCheckAddress}
									renderAddressValidation={() =>
										renderAddressValidation(
											shouldValidateAddress,
											setShouldValidateAddress
										)
									}
									setIsRequestAddressOutsideLaval={
										setIsRequestAddressOutsideLaval
									}
									setIsAddressOutsideLavalMessageShow={
										setIsAddressOutsideLavalMessageShow
									}
									isAddressOutsideLavalMessageShow={
										isAddressOutsideLavalMessageShow
									}
								/>
							</section>
						</Collapse>

						<Collapse
							isOpened={
								subSteps !== undefined &&
								REQUEST_TN_DETAILS_STEPS_NAME.MAP !== subSteps.position
							}
						>
							<SelectionPDF
								name={`${request?.name}`}
								files={extraFiles}
								hasFileError={hasFileError}
								hasFileRequiredError={hasFileRequiredError}
								inputs={inputs}
								errors={errors}
								inputFiles={inputFiles}
								onSetInputFiles={setInputFiles}
								onFixError={setErrors}
								onSetFiles={setExtraFiles}
								onSetHasFileError={setHasFileError}
								onSetHasFileRequiredError={setHasFileRequiredError}
								onSetInputs={setInputs}
								subSteps={subSteps}
								onSetSubSteps={setSubSteps}
								filesInputs={filesInputs}
								onCustomError={setCustomError}
								steps={steps}
								currentStep={currentStep}
								setDisableNextButton={setDisableNextButton}
								setRequiredFieldAttributes={setRequiredFieldAttributes}
								setRequiredFileAttributes={setRequiredFileAttributes}
							/>
						</Collapse>

						{/* <DisplayInforModal
							isModalOpen={isAddressOutsideLavalModalOpen}
							message={pageAssets?.modal_request_address_must_be_inside_Laval}
							close={() => setIsAddressOutsideLavalModalOpen(false)}
							onPrimaryButton={() => {
								if (closeLink) {
									navigate(closeLink)
								}
							}}
							cancelButtonText={pageAssets?.label_return_to_form}
							primaryButtonText={pageAssets?.label_quit}
						/> */}

						<div className={pageUtils.classes.buttonContainer}>
							<button
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									pageUtils.classes.buttonOutline
								])}
								onClick={() => {
									goToPreviousStep()
									!disableNextButton && setDisableNextButton(true)
								}}
								type="button"
							>
								{formatStrapiText(pageAssets?.button_previous)}
							</button>
							<button
								className={joinClasses([
									pageUtils.classes.btn,
									pageUtils.classes.button,
									errors.length > 0 ||
									customError ||
									!disableNextButton ||
									isValidating
										? joinClasses([
												pageUtils.classes.centerItem,
												pageUtils.classes.btnDisabled
										  ])
										: ''
								])}
								onClick={async () => {
									if (
										subSteps !== undefined &&
										REQUEST_TN_DETAILS_STEPS_NAME.MAP == subSteps.position
									) {
										if (shouldCheckAddress) {
											const isValid = await validateAddressBeforeNextStep(
												() => {},
												setShouldValidateAddress
											)
											if (!isValid) return
										}

										// verifier si adresse est en dehors de Laval (no coordinates)
										if (
											isRequestAddressOutsideLaval &&
											[
												TNRequestNames.temporaryPesticideApplicationPermitForIndividuals,
												TNRequestNames.treePlanting,
												TNRequestNames.rainwaterBarrel,
												TNRequestNames.crackedHouses,
												TNRequestNames.heritageBuildingsRevitalizationProgram,
												TNRequestNames.replacementOilHeatingSystem,
												TNRequestNames.fireplaceGrant
											].includes(request?.name!)
										) {
											setDisableNextButton(false)
											setIsAddressOutsideLavalMessageShow(true)
											return
										}
									}
									goToNextStep()
								}}
								style={{ marginLeft: '5px' }}
								type="button"
								disabled={!disableNextButton}
							>
								{!isValidating ? (
									formatStrapiText(pageAssets?.button_save_and_continue)
								) : (
									<div className={`${pageUtils.classes.loader} spin`} />
								)}
							</button>
						</div>
					</form>
				)}

				{currentStep === steps.summary && (
					<section
						className={joinClasses([
							pageUtils.classes.container,
							pageUtils.classes.noPadding,
							pageUtils.classes.noBackground
						])}
					>
						<div
						// className={
						// 	requestHasPayment() ? pageUtils.classes.summaryContainer : ''
						// }
						>
							<div>
								<RequestFormPDFSummary
									name={`${request?.name}`}
									files={extraFiles}
									mapLabel={mapLabel}
									hasFileError={hasFileError}
									hasFileRequiredError={hasFileRequiredError}
									inputs={inputs}
									errors={errors}
									inputFiles={inputFiles}
									setCurrentStep={setCurrentStep}
									onSetInputFiles={setInputFiles}
									onFixError={setErrors}
									onSetFiles={setExtraFiles}
									onSetHasFileError={setHasFileError}
									onSetHasFileRequiredError={setHasFileRequiredError}
									onSetInputs={setInputs}
									subSteps={subSteps}
									steps={steps}
									onSetSubSteps={setSubSteps}
									filesInputs={filesInputs}
									currentStep={currentStep}
									address={customLocationState.address}
									cadastralAddress={customLocationState.cadastralAddress}
									apartment={customLocationState.apartment}
									city={customLocationState.city}
									postalCode={customLocationState.postalCode}
									isSummaryStep
									policy={getAcceptedTermsAndConditions(request)}
								/>
								{/* {!requestHasPayment() && renderPolicyAndSubmitBtn()} */}
								{renderPolicyAndSubmitBtn()}
								{loading && isRequestHasPayementOnDepot() && (
									<div className={pageUtils.classes.loadingOverlay}>
										<div className={`${pageUtils.classes.loader} spin`} />
										<div>
											<strong>
												{
													pageAssets?.createRequest_page_please_do_not_refresh_the_page
												}
											</strong>
										</div>
										<div>
											{pageAssets?.createRequest_page_sending_in_progress}
										</div>
									</div>
								)}
							</div>
						</div>
						{renderRequestFailedModal()}
					</section>
				)}

				{currentStep === steps.confirmation && (
					<section
						className={joinClasses([
							pageUtils.classes.container,
							pageUtils.classes.noPadding,
							pageUtils.classes.noBackground
						])}
					>
						<RequestConfirmation
							requestCreated={requestCreated}
							request={request}
							closeLink={closeLink}
							// TODO: once this condition is accepted for all other requests either remove this prop or set it to true
							useClassWrapper={showRequestConfirmationWrapper()}
							hasPayment={isRequestHasPayementOnDepot()}
							createdRequestWithInvoice={createdRequestWithInvoice}
						>
							<RequestFormPDFSummary
								name={`${request?.name}`}
								files={extraFiles}
								hasFileError={hasFileError}
								hasFileRequiredError={hasFileRequiredError}
								inputs={inputs}
								errors={errors}
								inputFiles={inputFiles}
								filesInputs={filesInputs}
								setCurrentStep={setCurrentStep}
								onSetInputFiles={setInputFiles}
								onFixError={setErrors}
								onSetFiles={setExtraFiles}
								onSetHasFileError={setHasFileError}
								onSetHasFileRequiredError={setHasFileRequiredError}
								onSetInputs={setInputs}
								subSteps={subSteps}
								steps={steps}
								onSetSubSteps={setSubSteps}
								currentStep={currentStep}
								isSummaryStep
								address={address}
								mapLabel={mapLabel}
								apartment={apartment}
								city={city}
								postalCode={postalCode}
								request={request}
								requestCreated={requestCreated}
								toPrint
								policy={getAcceptedTermsAndConditions(request)}
							/>
						</RequestConfirmation>
					</section>
				)}
			</div>
			{language === LanguageEnum.EN && <CompactFooter />}
		</div>
	)
}

export default CreateRequestPdf
